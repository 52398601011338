
    import { RezToursLanguageEnum } from "@/apiManager/_common/enums";
    import RoutesEnum from "@/router/routesEnum";
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

    @Component
    export default class PopUpAgreement extends Vue
    {
        @PropSync("open", { type: Boolean }) private openAgreement!: boolean;

        private agreement: string = "";

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        get getTitle(): string
        {
            return this.i18n(RezToursLanguageEnum.termsOfUse);
        }

        get getCloseLabel(): string
        {
            return this.i18n(RezToursLanguageEnum.close);
        }

        get getPrintLabel(): string
        {
            return this.i18n(RezToursLanguageEnum.print);
        }

        async onShow()
        {
            this.agreement = (await this.$apiManager.setting.getTermsAsync(this.g_Language))?.terms ?? "";
        }

        onPrintClick()
        {
            let routeData = this.$router.resolve({name: RoutesEnum.Agreement});
            window.open(routeData.href, "_blank");
        }
    }
