
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
    import ReservationPayload from "@/store/modules/reservation/models";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import { ReservationPaymentModel } from "@/apiManager/reservation/reservationData";

    @Component
    export default class PopUpCancelPayment extends Vue
    {
        @PropSync("open", { type: Boolean }) private syncedOpen!: boolean;
        @PropSync("payment", { type: Object }) private syncedPayment!: ReservationPaymentModel;
        @Prop({ type: String }) private readonly reservCode!: string;

        private onDeleteProcess: boolean = false;
        private txtDeleteProcess: string = "";
        private txtDeleteError: string = "";
        private txtTitle: string = "";

        mounted()
{
            this.txtTitle = this.i18n(this.getRezToursLanguageEnum.confirmation);
        }

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        onCancelPaymentModalCancelClick(event: Event)
        {
            if (this.onDeleteProcess)
            {
                event.preventDefault();
            }
            else
            {
                this.syncedPayment = new ReservationPaymentModel();
            }
        }

        async onCancelPaymentModalOkClick(event: Event)
        {
            event.preventDefault();

            if (!this.onDeleteProcess)
            {
                this.txtDeleteError = "";
                this.onDeleteProcess = true;
                this.txtDeleteProcess = this.i18n(this.getRezToursLanguageEnum.canceledPaymentMsgInProgress);
                try
                {
                    let payload = new ReservationPayload.Actions.CancelPayment(this.reservCode, this.syncedPayment.index);
                    await this.$tStore.dispatch(payload);
                    await this.sleep(1000);
                    this.syncedPayment = new ReservationPaymentModel();
                    this.syncedOpen = false;
                }
                catch
                {
                    await this.sleep(1000);
                    this.txtDeleteError = this.i18n(this.getRezToursLanguageEnum.canceledPaymentMsgError);
                }
                finally
                {
                    this.onDeleteProcess = false;
                    this.txtDeleteProcess = "";
                }
            }
        }

        sleep(ms: number)
        {
            return new Promise((resolve) =>
            {
                return setTimeout(resolve, ms);
            });
        }

        get components()
        {
            let components =
                {
                    "format-money": FormatMoney
                };

            return components;
        }
    }

