
    import { Component, Prop, Vue } from "vue-property-decorator";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import { ProductOptionModel } from "@/apiManager/product/productData";
    import { ReservationProductCategoryModel, ReservationProductGroupModel, ReservationRoomPaxModel } from "@/apiManager/reservation/reservationData";

    @Component
    export default class BookingInfo extends Vue
    {
        @Prop({ type: String }) private readonly productName!: string;
        @Prop({ type: String }) private readonly durationLabel!: string;
        @Prop({ type: Array }) private readonly productGroupList!: ReservationProductGroupModel[];
        @Prop({ type: String }) private readonly dateFrom!: string;
        @Prop({ type: String }) private readonly dateTo!: string;
        @Prop({ type: String }) private readonly dateFormat!: string;
        @Prop({ type: String }) private readonly quantity!: string;
        @Prop({ type: String }) private readonly pricingClass!: string;
        @Prop({ type: Number }) private readonly travelBagTotal!: number;
        @Prop({ type: Number }) private readonly deposit!: number;
        @Prop({ type: Array }) private readonly categoryList!: ReservationProductCategoryModel[];
        @Prop({type: String}) private readonly destination!: string;
        @Prop({type: String}) private readonly currency!: string;
        @Prop({ type: Array }) private readonly productOptionList!: ProductOptionModel[];
        @Prop({ type: String }) private readonly busPickupLabel!: string;

        private showCommission: boolean = false;

        get components()
        {
            let components =
                {
                    "format-money": FormatMoney
                };

            return components;
        }

        get getShowCommission(): boolean
        {
            return this.$tStore.state.configState.setting.license.showCommission;
        }

        get getCommissionInfo(): CommissionInfoModel
        {
            let commissionInfo: CommissionInfoModel = new CommissionInfoModel();
            let commissionTotal: number = 0;
            this.categoryList.forEach((category: ReservationProductCategoryModel) =>
            {
                commissionInfo.price += category.price;
                commissionInfo.taxes += category.taxes;
                commissionInfo.commission += category.commission;
                commissionInfo.commissionGST += category.commissionTax1;
                commissionInfo.commissionPST += category.commissionTax2;

                commissionTotal += (category.commission + category.commissionTax1 + category.commissionTax2) * category.quantity;
            });
            commissionInfo.totalWithCommission = this.travelBagTotal - commissionTotal;

            return commissionInfo;
        }

        get getPaxList(): ReservationRoomPaxModel[]
        {
            let listPax: ReservationRoomPaxModel[] = [];
            this.$tStore.state.reservationState.detail.listRoom.forEach((element) =>
            {
                listPax = listPax.concat(element.listPax);
            });
            return listPax;
        }

        get g_HasBusPickup(): boolean
        {
            return this.busPickupLabel.length > 0;
        }

        getOptionClassCategory(p_Option: ProductOptionModel): string
        {
            let result: string = "( ";
            let pricingClass: string = this.translateValue(p_Option.pricingClass);
            let pricingCategory: string = this.translateValue(p_Option.pricingCategory);

            if (pricingClass !== "Standard" && pricingClass.length > 0)
            {
                result = `${ result } ${ pricingClass }`;
            }

            if (pricingCategory !== "Standard" && pricingCategory.length > 0)
            {
                if (result !== "( ")
                {
                    result = `${result} - `;
                }

                result = `${ result } ${ pricingCategory }`;
            }

            if (result !== "( ")
            {
                result = `${result} )`;
            }
            else
            {
                result = "";
            }

            return result;
        }

        validatePaxBirthDate(p_BirthDate: string): boolean
        {
            if (!p_BirthDate)
            {
                return false;
            }
            if (p_BirthDate.length === 0)
            {
                return false;
            }
            if (p_BirthDate.startsWith("0"))
            {
                return false;
            }
            return true;
        }
    }

    class CommissionInfoModel
    {
        public price: number = 0;
        public taxes: number = 0;
        public commission: number = 0;
        public commissionGST: number = 0
        public commissionPST: number = 0;
        public totalWithCommission: number = 0;
    }

